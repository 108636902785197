import firebase from 'firebase/app';
import database from 'firebase/database';

const config = {
    apiKey: "AIzaSyAx8f1ajjLcYAJ7EJP9YEETXDRm0rhk1ug",
    authDomain: "react-firebase-blog-22e4f.firebaseapp.com",
    databaseURL: "https://react-firebase-blog-22e4f.firebaseio.com",
    projectId: "react-firebase-blog-22e4f",
    storageBucket: "react-firebase-blog-22e4f.appspot.com",
    messagingSenderId: "727310615708",
    appId: "1:727310615708:web:aa7b41195405fe72e95959",
    measurementId: "G-ZCWPCBW00G"
  };
  
let firebaseCache;

export const getFirebase = () => {
  if (firebaseCache) {
    return firebaseCache;
  }

  firebase.initializeApp(config);
  firebaseCache = firebase;
  return firebase;
};